import { main } from '../../../iife'

main.subscribe('load', e => {

    const Multicheckboxes = document.querySelectorAll(".multicheckbox");
    Multicheckboxes.forEach(multicheck => {

        const CheckOthersApproach = multicheck.querySelector("input[value='advisory_approach:others']");
        const CheckOthersApplication = multicheck.querySelector("input[value='application_form:others']");
        const CheckOthersMatter = multicheck.querySelector("input[value='advisory_matter:others']");
        const CheckOthers = CheckOthersApproach || CheckOthersApplication || CheckOthersMatter
        if (CheckOthers) {
            const ParentTA = multicheck.parentElement.querySelector(".text");
            if (CheckOthers.checked) {
                ParentTA.classList.remove("hideTA");
            }   else {
                ParentTA.classList.add("hideTA");
            }

            CheckOthers.addEventListener("click", function() {
                if (CheckOthers.checked) {
                    ParentTA.classList.remove("hideTA");
                }   else {
                    ParentTA.classList.add("hideTA");
                    ParentTA.querySelector("input[type=text]").value = null;
                }
            })
        }
    })
})

export default main



