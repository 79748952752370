import {main} from '../../../iife'

main.subscribe('load', e => {
    const Button = document.querySelector("button.btn-primary");

    // Inputs
    const RequiredInput = document.querySelectorAll("input[type=text][required=required]");
    RequiredInput.forEach(reqInput => {

        Button.addEventListener("click", function () {
            const inputMessage = reqInput.parentElement.querySelector(".error-message")
            let ErrorInput = inputMessage.parentElement.querySelector("input[required=required]");
            if (reqInput.value.length == 0) {
                ErrorInput.classList.add("ErrorBoxShadow");
                inputMessage.classList.remove("hideError");
            } else {
                if (inputMessage) {
                    ErrorInput.classList.remove("ErrorBoxShadow");
                    inputMessage.classList.add("hideError");
                }
            }
        })
    });

    // Textarea
    const RequiredTextarea = document.querySelectorAll("textarea[required=required]");
    RequiredTextarea.forEach(reqTA => {

        Button.addEventListener("click", function () {
            const inputMessage = reqTA.parentElement.querySelector(".error-message");
            let ErrorTA = inputMessage.parentElement.querySelector("textarea[required=required]");

            if (reqTA.value.length == 0) {
                ErrorTA.classList.add("ErrorBoxShadow");
                inputMessage.classList.remove("hideError");
            } else {
                if (inputMessage) {
                    ErrorTA.classList.remove("ErrorBoxShadow");
                    inputMessage.classList.add("hideError");
                }
            }
        })
    });

    // Email
    Button.addEventListener("click", function () {
        const RequiredMail = document.querySelectorAll("input[type=email][required=required]");

        RequiredMail.forEach(reqMail => {
            const email = reqMail.value;
            const inputMessage = reqMail.parentElement.querySelector(".error-message")

            function validateEmail(email) {
                const re = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
                return re.test(String(email).toLowerCase());
            }

            if (validateEmail(email)) {
                inputMessage.classList.add("hideError");
            } else {
                inputMessage.classList.remove("hideError");
            }
        })
    });

    // Telephone
    Button.addEventListener("click", function () {
        const RequiredTels = document.querySelectorAll("input[type=tel][required=required]");

        RequiredTels.forEach(reqTel => {
            const tel = reqTel.value;
            const inputMessage = reqTel.parentElement.querySelector(".error-message");


            if (tel !== '') {
                inputMessage.classList.add("hideError");
            } else {
                inputMessage.classList.remove("hideError");
            }
        })
    });

    // Radiobuttons
    const RequiredRadio = document.querySelectorAll(".radiobutton");
    RequiredRadio.forEach(reqRadio => {

        Button.addEventListener("click", function () {
            const Radios = reqRadio.querySelectorAll("input[required=required");

            Radios.forEach(radio => {
                const CheckedInput = radio.checked;
                const inputMessage = radio.closest(".form-group>.input").querySelector(".error-message");

                if (CheckedInput) {
                    inputMessage.classList.add("hideError");
                } else {
                    inputMessage.classList.remove("hideError");
                }
            })
        })
    });

    // Multicheckbox
    const RequiredMC = document.querySelectorAll(".inputs-list");
    RequiredMC.forEach(reqMCheck => {
        Button.addEventListener("click", function () {
            const Multichecks = reqMCheck.querySelectorAll(".multicheckbox input[type=checkbox");
            Multichecks.forEach(multicheck => {
                const CheckedInput = multicheck.checked;
                const inputMessage = multicheck.closest(".form-group>.input").querySelector(".error-message");

                if (CheckedInput) {
                    inputMessage.classList.add("hideError");
                } else {
                    inputMessage.classList.remove("hideError");
                }
            })
        })
    });

    Button.addEventListener("click", function () {
        const check = document.querySelector("#supervisorprofileform-1-checkboxPrivacyPolicy");
        if (check) {
            const CheckedInput = check.checked;
            const inputMessage = check.closest(".form-group>.input").querySelector(".error-message");

            if (CheckedInput) {
                inputMessage.classList.add("hideError");
            } else {
                inputMessage.classList.remove("hideError");
            }
        }
    });

    const urlInput = document.querySelector('input[type=url]');
    urlInput.addEventListener('invalid', function (event) {
        if (!event.target.validity.valid) {
            event.target.setCustomValidity('Bitte geben Sie Ihre Internetadresse in der Form "https://meine-domain.de" ein.');
        }
    });
});

export default main