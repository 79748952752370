import './Contexts/production'
import './stylesheets'
import Core from './core'

export let main = new Core()

window.addEventListener('load', e => {
    const modules = import.meta.glob([
        './App/**/*.js',
        './Extensions/**/*.js',
    ])

    const modulesArray = []

    for (const path in modules) {
        const promise = new Promise((_res, _rej) => {
            modules[path]().then(mod => _res([path, mod]))
        })

        modulesArray.push(promise)
    }

    Promise.all(modulesArray).then(modules => {
        for (const [path, mod] of modules) {
            if (mod.default === undefined) {
                console.warn(`iife.js: the module "${path}" has to export its main object!`)

                return false
            }

            main = mod.default
        }

        for (const listener of main.listeners['load']) {
            listener()
        }
    })
})
