import { main } from '../../../iife'

main.subscribe('load', e => {

    var Checkbox = document.querySelector("input[type=checkbox]");
    if( typeof(Checkbox) != 'undefined' && Checkbox != null ) {

        var AllCheckbox = document.querySelectorAll("input[type=checkbox]");

        AllCheckbox.forEach(checkbox => {

            checkbox.addEventListener('click', function() {
                
                if (checkbox.checked) {
                    checkbox.classList.add("CheckboxChecked");
                }   else {
                    checkbox.classList.remove("CheckboxChecked");
                }
            });

            if (checkbox.checked) {
                checkbox.classList.add("CheckboxChecked");
            }   else {
                checkbox.classList.remove("CheckboxChecked");
            }
        });
    }
})

export default main